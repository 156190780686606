var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"70px","justify-content":"center"},attrs:{"fluid":""}},[_c('alert',{ref:"alertComponent"}),_c('confirm-dialog',{ref:"confirmDialog",attrs:{"title":"Delete playlist"},on:{"confirm":function($event){return _vm.deletePlaylist($event)}}}),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.playlists,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.image !== null && item.image !== '')+" ")]}},{key:"item.videos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.videos.length)+" ")]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Playlist', params: { id: item.id }}}},[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"#fbc02d"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"#f44336"},on:{"click":function($event){return _vm.showDeleteConfirmation(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }