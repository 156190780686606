<template>
    <v-container fluid style="padding-top: 70px; justify-content: center">
        <alert ref="alertComponent"></alert>
        <confirm-dialog ref="confirmDialog" title="Delete playlist" @confirm="deletePlaylist($event)"></confirm-dialog>

        <v-card>
            <!--search input-->
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                              hide-details></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="playlists" :search="search" disable-pagination hide-default-footer>

                <!--image-->
                <template v-slot:[`item.image`]="{ item }">
                    {{ item.image !== null && item.image !== '' }}
                </template>

                <!--videos-->
                <template v-slot:[`item.videos`]="{ item }">
                    {{ item.videos.length }}
                </template>

                <!--update button-->
                <template v-slot:[`item.update`]="{ item }">
                    <router-link :to="{ name: 'Playlist', params: { id: item.id }}">
                        <v-btn class="mx-2" icon color="#fbc02d">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </router-link>
                </template>

                <!--delete button-->
                <template v-slot:[`item.delete`]="{ item }">
                    <v-btn @click="showDeleteConfirmation(item.id)" class="mx-2" icon color="#f44336">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>

            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Alert from "@/components/Alert.component";
import ConfirmDialog from "@/components/ConfirmDialog.component";
import Services from "@/services/api";

export default {
    name: "Playlists",
    components: {Alert, ConfirmDialog},
    data: () => ({
        search: '',
        headers: [
            {text: 'Id', value: 'id'},
            {text: 'Title VF', value: 'titleVF'},
            {text: 'Title VO', value: 'titleVO'},
            {text: 'Has an image', value: 'image'},
            {text: 'Type', value: 'type'},
            {text: 'Videos', value: 'videos'},
            {text: '', value: 'update', sortable: false},
            {text: '', value: 'delete', sortable: false}
        ],
        playlists: []
    }),
    async mounted() {
        await this.loadPlaylists();
    },
    methods: {
        async loadPlaylists() {
            try {
                this.playlists = await Services.Playlists.getAllPlaylists();
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
        },
        showDeleteConfirmation(id) {
            let playlistToDelete = this.playlists.find(m => m.id === id);
            let dialogText = `Do you really want to delete the playlist '${playlistToDelete.titleVO}' ?`;
            this.$refs.confirmDialog.setData({id, text: dialogText});
        },
        /**
         * delete a playlist
         * @param id
         * @returns {Promise<void>}
         */
        async deletePlaylist(id) {
            try {
                await Services.Playlists.deletePlaylist(id);
                let index = this.playlists.findIndex(m => m.id === id);
                this.playlists.splice(index, 1);
                this.$refs.alertComponent.showSuccessMessage("Playlist successfully deleted.");
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
        }
    }
}
</script>
